<template>
    <div v-if="this.lang!='en-US'">
        <slider-r-u/>
    </div>
    <div v-else>
        <slider-e-n/>
    </div>
</template>

<script>
import '../../plugins/i18n'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import SliderRU from './SliderRU'
import SliderEN from './SliderEN'
export default {
  components: {
    swiper,
    swiperSlide,
    SliderRU,
    SliderEN
  },
  data () {
    return {
    }
  },
  computed: {
    lang () { return this.$i18n.locale },
    langRuDiv () { if (this.$i18n.locale == 'ru') { return 'display: block' } return 'display: none' },
    langEnDiv () { if (this.$i18n.locale != 'ru') { return 'display: block' } return 'display: none' }
  },
  watch: {
    lang1 () { return this.$i18n.locale }
  },
  methods: {
    pushAboutCompany () {
      this.$router.push('about_company')
    },
    pushTypesOfActivities () {
      this.$router.push('/types_of_activities')
    },
    pushPartnership () {
      this.$router.push('/partnership')
    }
  }
}
</script>

<style lang="scss" scoped>
  .swiper-slide {
    font-size: 18px;
    color:#000;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 60px;
    background-color: transparent!important;
    justify-content: space-around!important;
  }
  .parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    background-image:  url(../../../public/img/slider/background.jpg);
    opacity: 0.3;
  }
  .swiper-slide .title {
    font-size: 41px;
    font-weight: 300;
  }
  .swiper-slide .subtitle {
    font-size: 21px;
  }
  .swiper-slide .text {
    font-size: 14px;
    max-width: 400px;
    line-height: 1.3;
  }
</style>
