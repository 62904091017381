import * as types from '../mutation-types'
import axios from 'axios'

// state
export const state = {
  feedback: false,
  feedbackLoad: false,
  feedbackError: false,
  work: false,
  offergoodsformLoad: false,
  reportformLoad: false,
  workformLoad: false,
  workformError: false,
  requestFormLoad:false,
  requestFormError:false
}

// getters
export const getters = {
  requestFormLoad: state =>state.requestFormLoad,
  requestFormError: state =>state.requestFormError,
  feedback: state => state.feedback,
  feedbackLoad: state => state.feedbackLoad,
  feedbackError: state => state.feedbackError,
  work: state => state.work,
  offergoodsformLoad: state => state.offergoodsformLoad,
  workformLoad: state => state.workformLoad,
  workformError: state => state.workformError
}

// mutations
export const mutations = {
  'SHOW_ERROR' (state, { form, value }) { state[form + 'Error'] = value },
  'SHOW_LOADER' (state, { form, value }) { state[form + 'Load'] = value },
  'SHOW_FORM' (state, { form, value }) { console.log(form); state[form] = value }

}

// actions
export const actions = {
  'SEND_FEDBACK': async function ({ commit }, { name, email, text }) {
    commit('SHOW_LOADER', { 'form': 'feedback', 'value': true })
    axios.post('/api/feed_back', {
      name: name,
      email: email,
      text: text
    })
      .then(function (response) {
        commit('SHOW_LOADER', { 'form': 'feedback', 'value': false })
        commit('SHOW_FORM', { 'form': 'feedback', 'value': false })
      })
      .catch(function (error) {
        commit('SHOW_LOADER', { 'form': 'feedback', 'value': false })
      })
  },
  'SHOW_FEDBACKERROR':async function({commit})
  {commit('SHOW_ERROR', { 'form': 'feedback', 'value': true })}
  ,
  'SEND_REPORTFORM': async function ({ commit }, { name, contact_name, email, phone, text }) {
    commit('SHOW_LOADER', { 'form': 'reportform', 'value': true })
    axios.post('/api/report', {
      name: name,
      contact_name: contact_name,
      email: email,
      phone: phone,
      text: text
    })
      .then(function (response) {
        commit('SHOW_LOADER', { 'form': 'reportform', 'value': true })
      })
      .catch(function (error) {
      })
  },
  'SEND_OFFERGOODSFORM': async function ({ commit }, { name, company, site, region, contact_name, phone, email, text, files }) {
    commit('SHOW_LOADER', { 'form': 'offergoodsform', 'value': true })
    var formData = new FormData()
    formData.append('name', name)
    formData.append('company', company)
    formData.append('site', site)
    formData.append('region', region)
    formData.append('contact_name', contact_name)
    formData.append('phone', phone)
    formData.append('email', email)
    formData.append('text', text)
    for (var i = 0; i < files.length; i++) {
      formData.append('files[]', files[i], files[i].name)
    }
    axios.post('/api/offer_goods', formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
      .then(function (response) {
        commit('SHOW_LOADER', { 'form': 'offergoodsform', 'value': true })
      })
      .catch(function (error) {
      })
  },
  'SEND_WORKFORM': async function ({ commit }, { fio, vacantion, city, phone, email, text, files }) {
    commit('SHOW_LOADER', { 'form': 'workform', 'value': true })
    var formData = new FormData()
    formData.append('fio', fio)
    formData.append('vacantion', vacantion)
    formData.append('city', city)
    formData.append('phone', phone)
    formData.append('email', email)
    formData.append('text', text)
    for (var i = 0; i < files.length; i++) {
      formData.append('files[]', files[i], files[i].name)
    }
    axios.post('/api/work', formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
      .then(function (response) {
        commit('SHOW_LOADER', { 'form': 'workform', 'value': true })
      })
      .catch(function (error) {
      })
  },
  'SHOW_WORKFORMERROR':async function({commit})
  {commit('SHOW_ERROR', { 'form': 'workform', 'value': true })}
  ,
  'SEND_REQUESTFORM': async function({ commit },{name_org,adress,email,phone,request_type})
  {commit('SHOW_LOADER', { 'form': 'requestForm', 'value': true })
  var formData = new FormData()
    formData.append('name_org', name_org)
    formData.append('adress', adress)
    formData.append('phone', phone)
    formData.append('email', email)
    formData.append('request_type', request_type)
    axios.post('/api/request_form', formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
      .then(function (response) {
        commit('SHOW_LOADER', { 'form': 'requestForm', 'value': true })
      })
      .catch(function (error) {
      })
  },
  'SHOW_REQUESTERROR':async function({commit})
  {commit('SHOW_ERROR', { 'form': 'requestForm', 'value': true })}
  ,

  'SHOW_FORM': async function ({ commit }, form) {
    commit('SHOW_FORM', { 'form': form, 'value': true })
  },
  'HIDE_FORM': async function ({ commit }, form) {
    commit('SHOW_FORM', { 'form': form, 'value': false })
  }
}
