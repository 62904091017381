<template>
  <div id="app">
    <v-app
      v-scroll:#scroll-target="onScroll"
      style="background-color: #f5f5f5!important;">
      <v-container fluid class="no-indentation">
        <loading ref="loading"/>
        <Locale></Locale>
        <ToolBar></ToolBar>
        <main-slider></main-slider>
        <component v-if="layout" :is="layout"/>
        <button-up :offsetTop="offsetTop"></button-up>
        <Footer></Footer>
      </v-container>
    </v-app>
  </div>
</template>
<script>
import './assets/sass/app.scss'
import './plugins/i18n.js'
import Locale from './components/ui/Locale'
import ToolBar from './components/ui/ToolBar'
import MainSlider from './components/ui/MainSlider'
import Footer from './components/ui/Footer'
import ButtonUp from './components/ui/ButtonUp'
const requireContext = require.context('@/layouts', false, /.*\.vue$/)
const layouts = requireContext.keys()
  .map(file =>
    [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
  )
  .reduce((components, [name, component]) => {
    components[name] = component.default || component
    return components
  }, { Locale, ToolBar, MainSlider, Footer, ButtonUp })

export default {
  el: '#app',
  data: () => ({
    layout: null,
    defaultLayout: 'default',
    offsetTop: 0
  }),
  components: layouts,

  metaInfo () {
    const { appName } = window.config

    return {
      // title: 'Технологии и инновации ',
      titleTemplate: `%s ${appName}`
    }
  },

  mounted () {
    this.$loading = this.$refs.loading
    var vm = this
    window.addEventListener('scroll', function (e) {
      var scrollPos = window.scrollY
      var winHeight = window.innerHeight
      var docHeight = document.documentElement.scrollHeight
      var perc = 100 * scrollPos / (docHeight - winHeight)
      vm.offsetTop = perc
    })
  },

  methods: {
    onScroll (e) {
      console.log(e)
      this.offsetTop = e.target.scrollTop
    },
    /**
       * Set the application layout.
       *
       * @param {String} layout
       */
    setLayout (layout) {
      if (!layout || !layouts[layout]) {
        layout = this.defaultLayout
      }

      this.layout = layouts[layout]
    }
  }
}
</script>
