<template>
    <v-container fluid class="no-indentation">
        <v-row class="no-indentation">
            <v-col class="no-indentation" cols="12">
                <v-card class="licenseCard">
                    <v-card-text>
                        <!-- swiper1 -->
                        <swiper :options="swiperOptionTop" class="gallery-top" ref="swiperTop">
                            <swiper-slide>
                                <a href="/img/licenses/lic_001.jpg" target="_blank">
                                    <img src="../../../public/img/licenses/lic_001.jpg">
                                </a>
                            </swiper-slide>
                            <swiper-slide>
                                <a href="/img/licenses/lic_002.jpg" target="_blank">
                                    <img src="../../../public/img/licenses/lic_002.jpg">
                                </a>
                            </swiper-slide>
                            <swiper-slide>
                                <a href="/img/licenses/lic_003.jpg" target="_blank">
                                    <img src="../../../public/img/licenses/lic_003.jpg">
                                </a>
                            </swiper-slide>
                            <swiper-slide>
                                <a href="/img/licenses/lic_004.jpg" target="_blank">
                                    <img src="../../../public/img/licenses/lic_004.jpg">
                                </a>
                            </swiper-slide>
                            <swiper-slide>
                                <a href="/img/licenses/lic_005.jpg" target="_blank">
                                    <img src="../../../public/img/licenses/lic_005.jpg">
                                </a>
                            </swiper-slide>
                            <swiper-slide>
                                <a href="/img/licenses/lic_006.jpg" target="_blank">
                                    <img src="../../../public/img/licenses/lic_006.jpg">
                                </a>
                            </swiper-slide>
                            <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                            <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                        </swiper>
                        <!-- swiper2 Thumbs -->
                        <swiper :options="swiperOptionThumbs" class="gallery-thumbs" ref="swiperThumbs">
                            <swiper-slide>
                                <a href="/img/licenses/lic_001.jpg" target="_blank">
                                    <img src="../../../public/img/licenses/lic_001.jpg">
                                </a>
                            </swiper-slide>
                            <swiper-slide>
                                <a href="/img/licenses/lic_002.jpg" target="_blank">
                                    <img src="../../../public/img/licenses/lic_002.jpg">
                                </a>
                            </swiper-slide>
                            <swiper-slide>
                                <a href="/img/licenses/lic_003.jpg" target="_blank">
                                    <img src="../../../public/img/licenses/lic_003.jpg">
                                </a>
                            </swiper-slide>
                            <swiper-slide>
                                <a href="/img/licenses/lic_004.jpg" target="_blank">
                                    <img src="../../../public/img/licenses/lic_004.jpg">
                                </a>
                            </swiper-slide>
                            <swiper-slide>
                                <a href="/img/licenses/lic_005.jpg" target="_blank">
                                    <img src="../../../public/img/licenses/lic_005.jpg">
                                </a>
                            </swiper-slide>
                            <swiper-slide>
                                <a href="/img/licenses/lic_006.jpg" target="_blank">
                                    <img src="../../../public/img/licenses/lic_006.jpg">
                                </a>
                            </swiper-slide>
                        </swiper>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: { swiper, swiperSlide },
  data () {
    return {
      swiperOptionTop: {
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        loop: true,
        grabCursor: true,
        progressbarOpposite: true
      },
      swiperOptionThumbs: {
        spaceBetween: 8,
        slidesPerView: 3,
        touchRatio: 0.2,
        slideToClickedSlide: true,
        grabCursor: true,
        progressbarOpposite: true,
        direction: 'horizontal'
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper
      const swiperThumbs = this.$refs.swiperThumbs.swiper
      swiperTop.controller.control = swiperThumbs
      swiperThumbs.controller.control = swiperTop
    })
  }
}
</script>
