// auth.js
export const LOGOUT = 'LOGOUT'
export const GET_TOKEN_FAIL = 'GET_TOKEN_FAIL'
export const SET_CURRENT_CHANGE_USER = 'SET_CURRENT_CHANGE_USER'
export const GET_USER_LIST_SUCCES = 'GET_USER_LIST_SUCCES'
export const GET_USER_SETTINGS_SUCCES = 'GET_USER_SETTINGS_SUCCES'
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'

export const GET_LIST_REGISTER_FIELD_SUCCESS = 'GET_LIST_REGISTER_FIELD_SUCCESS'
export const GET_LIST_REGISTER_FIELD_FAIL = 'GET_LIST_REGISTER_FIELD_FAIL'
export const ADD_FIELD_TO_REGISTER_FAIL = 'ADD_FIELD_TO_REGISTER_FAIL'
export const ADD_FIELD_TO_REGISTER_SUCCESS = 'ADD_FIELD_TO_REGISTER_SUCCESS'
export const DELETE_FIELD_TO_REGISTER_FAIL = 'DELETE_FIELD_TO_REGISTER_FAIL'
export const DELETE_FIELD_TO_REGISTER_SUCCESS = 'DELETE_FIELD_TO_REGISTER_SUCCESS'

// lang.js
export const SET_LOCALE = 'SET_LOCALE'

// news.js
export const SET_NEWS = 'SET_NEWS'
export const SET_LIMIT_AND_OFFSET = 'SET_LIMIT_AND_OFFSET'
