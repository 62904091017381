<template>
    <div class="contentText">
        <v-form ref="form"
                v-model="valid"
                lazy-validation
        >
            <v-row>
                <v-col cols="12" md="10" lg="9">
                    <v-text-field v-model="name_org"
                                  :counter="50"
                                  :rules="name_orgRules"
                                  :label="$t('message.partnershipForSuppliersForm1')"
                                  class="inputStyle"
                                  prepend-inner-icon="house"
                                  required
                                  outlined
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="10" lg="9">
                    <v-text-field v-model="adress"
                                  :label="$t('message.partnershipForSuppliersForm2')"
                                  class="inputStyle"
                                  prepend-inner-icon="place"
                                  outlined
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="10" lg="9">
                    <v-text-field v-model="email"
                                  :label="$t('message.partnershipForSuppliersForm3')"
                                  :rules="this.emailRules"
                                  class="inputStyle"
                                  prepend-inner-icon="alternate_email"
                                  outlined
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="10" lg="9">
                    <VuePhoneNumberInput v-model="phone"
                                         v-on:update="updatePhone"
                                         v-bind:translations="myTranslation"
                                         :rules="this.emailRules"
                                         required
                                         class="mt-lg-2 mt-md-0"
                    />
                </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="10" lg="9">
                <v-select v-model="request_type"
                          :items="this.items"
                          :rules="this.request_typeRules"
                          :label="$t('message.partnershipForSuppliersForm5')"
                          class="Style mt-6"
                          prepend-inner-icon="done"
                          required
                          outlined/>
              </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" lg="4" md="5" sm="4">
                    <v-btn class="px-15"
                           outlined
                           x-large
                           v-on:click="send()"
                           color="light-green darken-4"
                    >{{ $t('message.partnershipForSuppliersForm10') }}</v-btn>
                    <v-dialog v-model="dialog"
                              width="300"
                    >
                        <v-card
                        >
                            <v-card-text class="pt-6 pb-8 circle-check">
                                <i class="material-icons mr-5 mt-3 ml-2">check_circle</i>
                                <p>{{ $t('message.partnershipForSuppliersForm13') }}</p>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialog_error"
                              width="300"
                    >
                        <v-card
                        >
                            <v-card-text class="pt-6 pb-8 circle-check">
                                <i class="cansel-icon material-icons mr-5 mt-3 ml-2">cancel</i>
                                <p>{{ $t('message.partnershipForSuppliersForm14') }}</p>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-col>

            </v-row>
        </v-form>
    </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input'
import swal from "sweetalert2";
export default {
  components: { VuePhoneNumberInput },
  methods: { updatePhone: function (value) { console.log(value) },
    onUploadFiles: function (files) { this.files = files },

    send: function () {
    if(this.validateForm)
      this.$store.dispatch('mail/SEND_REQUESTFORM', {
        'name_org': this.name_org,
        'adress': this.adress,
        'phone': this.phone,
        'email': this.email,
        'request_type': this.request_type
      })
    else
      this.$store.dispatch('mail/SHOW_REQUESTERROR')
    }
  },
  computed: {
    validateForm: function (){
      if (!this.name_org||!(this.name_org.length<50))
        return false
      if (!this.request_type)
        return false
      if (!this.email||!/.+@.+\..+/.test(this.email))
        return false
      return true
    },
    myTranslation () {
      return {
        countrySelectorLabel: this.$t('message.partnershipForSuppliersForm6'),
        countrySelectorError: this.$t('message.partnershipForSuppliersForm6Error'),
        phoneNumberLabel: this.$t('message.partnershipForSuppliersForm7'),
        example: this.$t('message.partnershipForSuppliersForm7Example')
      }
    },
    dialog: {
      get () {
        return this.$store.state['mail']['requestFormLoad'] },
      set (value) {
        this.name_org = ''
        this.adress = ''
        this.phone = ''
        this.email = ''
        this.request_type = ''
        this.$store.dispatch('mail/HIDE_FORM', 'requestFormLoad')
      }
    },
    dialog_error: {
      get () {
        return this.$store.state['mail']['requestFormError'] },
      set (value) {
        this.$store.dispatch('mail/HIDE_FORM', 'requestFormError')
      }
    },
  },
  name: 'OfferGoodsForm',
  data () {
    return {
      items:['заявка на ремонт','заявка на техническое обслуживание','заявка на заключение комплексного договора на техническое обслуживание и ремонт на календарный год'],
      valid: true,
      name_org: '',
      adress: '',
      request_type: '',
      request_typeRules:[
          v => !!v || 'Обязательное поле'
      ],
      name_orgRules: [
        v => !!v || 'Обязательное поле',
        v => (v && v.length <= 50) || 'Название организации должно содержать не более 50 символов'
      ],
      email: '',
      emailRules: [
        v => !!v || 'Обязательное поле',
        v => /.+@.+\..+/.test(v) || 'Электронная почта должна быть действительной'
      ],
      phone: '',
       }
  }
}
</script>

<style scoped>

</style>
