<template>
    <v-footer color="#3B3B3B"
              padless
    >
        <v-container fluid>
            <v-row class="no-indentation" style="margin-top:72px">
                <v-col offset-lg="1" offset-md="1" offset-sm="1" col="2" lg="2" md="2" sm="5">
                    <v-row class="no-indentation">
                        <v-col class="no-indentation">
                            <a @click="pushAboutCompany()" class="footerHeading">{{ $t('message.submenu1') }}</a>
                        </v-col>
                    </v-row>
                    <v-row class="no-indentation">
                        <v-col class="no-indentation mt-lg-5 mt-md-5 mt-sm-3 mobileSubHeading">
                            <a @click="pushManagement()" class="footerSubHeading">{{ $t('message.aboutUsSubmenu1') }}</a>
                        </v-col>
                    </v-row>
                    <v-row class="no-indentation">
                        <v-col class="no-indentation mt-lg-5 mt-md-5 mt-sm-3 mobileSubHeading">
                            <a @click="pushDocuments()" class="footerSubHeading">{{ $t('message.aboutUsSubmenu2') }}</a>
                        </v-col>
                    </v-row>
                    <v-row class="no-indentation">
                        <v-col class="no-indentation mt-lg-5 mt-md-5 mt-sm-3 mobileSubHeading">
                            <a @click="pushTypesOfActivities()" class="footerSubHeading">{{ $t('message.aboutUsSubmenu3') }}</a>
                        </v-col>
                    </v-row>
                    <v-row class="no-indentation">
                        <v-col class="no-indentation mt-lg-5 mt-md-5 mt-sm-3 mobileSubHeading">
                            <a @click="pushAnticorruptionPolicy()" class="footerSubHeading">{{ $t('message.aboutUsSubmenu4') }}</a>
                        </v-col>
                    </v-row>
                    <v-row class="no-indentation">
                        <v-col class="no-indentation mt-lg-5 mt-md-5 mt-sm-3 mobileSubHeading">
                            <a @click="pushAllNews()" class="footerSubHeading">{{ $t('message.aboutUsSubmenu5') }}</a>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col col="2" lg="2" md="2" sm="6">
                    <v-row class="no-indentation">
                        <v-col class="no-indentation">
                            <a @click="pushPartnership()" class="footerHeading">{{ $t('message.submenu2') }}</a>
                        </v-col>
                    </v-row>
                    <v-row class="no-indentation">
                        <v-col class="no-indentation mt-lg-5 mt-md-5 mt-sm-3 mobileSubHeading">
                            <a @click="pushLogistics()" class="footerSubHeading">{{ $t('message.partnershipSubmenu1') }}</a>
                        </v-col>
                    </v-row>
                    <v-row class="no-indentation">
                        <v-col class="no-indentation mt-lg-5 mt-md-5 mt-sm-3 mobileSubHeading">
                            <a @click="pushForSuppliers()" class="footerSubHeading">{{ $t('message.partnershipSubmenu2') }}</a>
                        </v-col>
                    </v-row>
                    <v-row class="no-indentation">
                        <v-col class="no-indentation mt-lg-5 mt-md-5 mt-sm-3 mobileSubHeading">
                            <a @click="pushOurPartners()" class="footerSubHeading">{{ $t('message.partnershipSubmenu3') }}</a>
                        </v-col>
                    </v-row>
                    <v-row class="no-indentation">
                        <v-col class="no-indentation mt-lg-5 mt-md-5 mt-sm-3 mobileSubHeading" style="display: none">
                            <a @click="pushOurClients()" class="footerSubHeading">{{ $t('message.partnershipSubmenu4') }}</a>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col offset-lg="0" offset-md="0" offset-sm="1" col="2" lg="2" md="2" sm="5">
                    <v-row class="no-indentation">
                        <v-col class="no-indentation">
                            <a @click="pushCareer()" class="footerHeading">{{ $t('message.submenu3') }}</a>
                        </v-col>
                    </v-row>
                    <v-row class="no-indentation">
                        <div class="no-indentation mt-lg-6 mt-md-6 mt-sm-5 mt-4 text-center py-2 mobileSubHeading footerLogo">
                            <img src="../../../public/img/logos/logo_TII.svg"/>
                        </div>
                    </v-row>
                </v-col>
                <v-col col="2" lg="2" md="4" sm="6">
                    <v-row class="no-indentation">
                        <v-col class="no-indentation">
                            <a @click="pushContact()" class="footerHeading">{{ $t('message.submenu4') }}</a>
                        </v-col>
                    </v-row>
                    <v-row class="no-indentation">
                        <v-col class="no-indentation mt-lg-5 mt-md-5 mt-sm-3 mobileSubHeading">
                            <span class="footerContacts">{{ $t('message.contactText1') }}</span>
                        </v-col>
                    </v-row>
                    <v-row class="no-indentation">
                        <v-col class="no-indentation mt-lg-3 mt-md-3 mt-sm-2 mt-2">
                            <a href="tel:+ 7(930)305-87-00" class="footerContacts footerHover" style="color: #fff">
                                <v-icon>phone</v-icon>+7(930)305-87-00
                            </a>
                        </v-col>
                    </v-row>
                    <v-row class="no-indentation">
                        <v-col class="no-indentation mt-lg-3 mt-md-3 mt-sm-2 mt-2">
                            <a href="mailto:ao-ti.info@mail.ru" class="footerContacts footerHover" style="color: #fff">
                                <v-icon>email</v-icon>ao-ti.info@mail.ru
                            </a>
                        </v-col>
                    </v-row>
                </v-col>
                <v-btn absolute
                       dark
                       fab
                       top
                       right
                       color="#BACF34"
                       x-large
                       @click.stop="showFeedbackForm()"
                >
                    <v-icon>{{ svgEmail }}</v-icon>
                </v-btn>
            </v-row>
                  <v-row justify="center">
                    <v-dialog v-model="dialog"
                                max-width="568"
                      >
                          <v-card class="pa-8">
                              <v-card-title class="headline" style="color: #4b9600!important;">{{ $t('message.feedBackForm') }}</v-card-title>
                              <v-card-text>
                                  <feedback ></feedback>
                              </v-card-text>
                          </v-card>
                      </v-dialog>
                  </v-row>
                    <hr class="my-6 hrComponent">
                    <v-row>
                        <v-col offset-lg="1" offset-md="1" offset-sm="1" col="12">
                            <a href="tel:+ 7(930)305-87-00" class="footerContactNumber">+7(930)305-87-00</a>
                        </v-col>
                    </v-row>
            <v-row class="mb-6">
                <v-col offset-lg="1" offset-md="1" offset-sm="1" col="12" class="footerInfo">
                    <span>{{ $t('message.contactInfo') }}</span>
<!--                    <v-icon class="footerInfo">{{ svgIcon }}</v-icon>-->
                    <i class="material-icons footerInfo">extension</i>
                    <span>{{ $t('message.contactITMosaic') }}</span>
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>
<script>
import '../../plugins/i18n.js'
import Feedback from '../ui/FeedbackForm'
import { mdiEmailOutline, mdiChevronUp, mdiBorderNone } from '@mdi/js'
export default {
  components: { Feedback },
  data () {
    return {
      svgEmail: mdiEmailOutline,
      svgUp: mdiChevronUp,
      svgIcon: mdiBorderNone
    }
  },
  computed: {
    dialog: {
      get () { return this.$store.state['mail']['feedback'] },
      set (value) {
        if (value === false) {
          this.$store.dispatch('mail/HIDE_FORM', 'feedback')
        }
      }
    }
  },
  methods: {
    showFeedbackForm () {
      this.$store.dispatch('mail/SHOW_FORM', 'feedback')
    },
    hideFeedbackForm () {
      this.$store.dispatch('mail/HIDE_FORM', 'feedback')
    },
    pushAboutCompany () {
      this.$router.push('about_company')
    },
    pushManagement () {
      this.$router.push('/management')
    },
    pushDocuments () {
      this.$router.push('/documents')
    },
    pushTypesOfActivities () {
      this.$router.push('/types_of_activities')
    },
    pushAnticorruptionPolicy () {
      this.$router.push('/anticorruption_policy')
    },
    pushAllNews () {
      this.$router.push('/news')
    },
    pushPartnership () {
      this.$router.push('/partnership')
    },
    pushLogistics () {
      this.$router.push('/logistics')
    },
    pushForSuppliers () {
      this.$router.push('/for_suppliers')
    },
    pushOurPartners () {
      this.$router.push('/our_partners')
    },
    pushOurClients () {
      this.$router.push('/our_clients')
    },
    pushCareer () {
      this.$router.push('/career')
    },
    pushContact () {
      this.$router.push('/contacts')
    }
  }
}
</script>
