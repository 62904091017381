import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: store.getters['lang/locale'],
  messages: {
    'ru': {
      message: {
        companyName: 'Технологии и инновации',
        slogan: 'с заботой о Вашем здоровье',
        linkView: 'Смотреть',
        submenu1: 'О компании',
        submenu2: 'Партнерство',
        submenu3: 'Карьера',
        submenu4: 'Контакты',
        sliderHeader1: 'Компания',
        sliderHeader2: 'Деятельность',
        sliderHeader3: 'Возможности',
        sliderText1: 'АО «Технологии и инновации»  - компания, занимающая ведущую позицию по поставкам и сервисному ' +
            'обслуживанию медицинской техники на территории Смоленской, Брянской и Калужской областей.',
        sliderText2: 'При проведении сервисного обслуживания медицинской техники главный упор делается на качество и ' +
            'оперативность работ, а также максимальное продление срока эксплуатации медицинского изделия с сохранением ' +
            'его эксплуатационных характеристик.',
        sliderText3: 'Предоставляем полный спектр услуг, связанных с сервисным обслуживанием медицинской техники',
        sliderButton1: 'Узнать подробнее',
        sliderButton2: 'Узнать подробнее',
        sliderButton3: 'Узнать подробнее',
        allNews: 'Все новости',
        contentUnit1: 'тыс.',
        contentUnit2: 'кв.м',
        contentUnit3: 'млн.руб.',
        contentUnit4: 'куб.м',
        contentUnit5: '%',
        contentUnit6: 'млн.куб.м',
        contentUnit7: 'кв.км',
        contentNumericHead: 'Компания в цифрах',
        contentNumeric1: 'Ремонт более 1000 видов медицинского оборудования',
        contentNumeric2: 'Площадь мастерских помещений',
        contentNumeric3: 'Ежегодный оборот: более 200 млн. руб.',
        contentNumeric4: 'Более 10 квалифициронных сотрудников',
        contentNum5: 'Достаточная',
        contentNumeric5: 'Доля на рынке',
        contentNum6: 'Россия и страны СНГ',
        contentNum7: 'Обширная',
        contentNum8: 'Достаточный',
        contentNumeric6: 'Охват АО Технологии и инновации',
        contentNumeric17: 'Площадь складских помещений',
        contentNumeric18: 'Более 10000 успешно выполненых ремонтов',
        contentNumeric19: 'Ремонт более 1000 видов медицинского оборудования',
        contentNumeric7: 'Сотрудничество с более 50 производителями медицинского оборудования',
        contentNumeric8: 'Более 50 аптек',
        contentNumeric9: 'Более 100 производителей',
        aboutUsSubmenu1: 'Руководство',
        aboutUsManagementFIO1: 'Радговская Елизавета Владимировна',
        aboutUsManagementPost1: 'Генеральный директор',
        aboutUsManagementFIO2: 'Карпов Алексей Анатольевич',
        aboutUsManagementPost2: 'Главный инженер по ремонту и техническому обслуживанию медицинской техники',
        aboutUsManagementFIO3: 'Мищенков Виталий Викторович',
        aboutUsManagementPost3: 'Инженер по ремонту и техническому обслуживанию медицинской техники',
        aboutUsManagementFIO4: 'Ситников Сергей Владимирович',
        aboutUsManagementPost4: 'Инженер по ремонту и техническому обслуживанию медицинской техники',
        aboutUsManagementFIO5: 'Петров Валерий Петрович ',
        aboutUsManagementPost5: 'Инженер сервисного центра и A&D',
        aboutUsManagementFIO6: 'Прохоренкова Евгения Александровна ',
        aboutUsManagementPost6: 'Главный бухгалтер',
        aboutUsManagementFIO7: ' Кондрашов Андрей Александрович ',
        aboutUsManagementPost7: 'Инженер по ремонту стоматологического оборудования',
        aboutUsSubmenu2: 'Документы',
        aboutUsSubmenu3: 'Виды деятельности',
        aboutUsSubmenu31: 'Лицензии и виды деятельности',
        aboutUsTypesOfActivitiesSveden: 'Сведения о видах деятельности организации в соответствии с данными ЕГРЮЛ',
        aboutUsTypesOfActivitiesEGRUL: 'Единый государственный реестр юридических лиц ФНС России',
        aboutUsTypesOfActivitiesOsnTitle: 'Основной',
        aboutUsTypesOfActivitiesOsn: 'Ремонт и сервисное обслуживание медицинской техники',
        aboutUsTypesOfActivitiesDop: 'Дополнительные',
        aboutUsTypesOfActivitiesDop1: 'Поставка, монтаж и ввод в эксплуатацию медицинского оборудования',
        aboutUsTypesOfActivitiesDop2: 'Поставка и установка запчастей на медицинское оборудование',
        aboutUsTypesOfActivitiesDop3: 'Консультативная поддержка по техническим вопросам',
        aboutUsTypesOfActivitiesDop4: 'Модернизация медицинских изделий старого парка',
        aboutUsSubmenu4: 'Антикоррупционная политика',
        aboutUsSubmenu5: 'Новости компании',
        aboutUsText1: 'Ремонт и сервисное обслуживание медицинского оборудования осуществляется высококвалифицированными ' +
            'инженерами, имеющими подготовку и действующие разрешительные документы на выполнение различных видов работ, в ' +
            'том числе специализированных. Все без исключения технические работники проходят курсы переподготовки и повышения ' +
            'квалификации по различным направлениям обслуживания медтехники в специализированных профильных центрах обучения.',
        aboutUsText2: 'Ремонт оборудования, в том числе капитальный, производится как в условиях лечебных учреждений, на месте ' +
            'размещения оборудования, так и на базе организованной ремонтной мастерской с опытно-производственным участком. ' +
            'Наличие опытного участка позволяет выполнять ремонтные работы любой сложности, и самостоятельно производить ' +
            'токарные и фрезеровочные операции для изготовления снятых с производства или труднодоступных механических деталей. ',
        aboutUsText3: 'Для максимально быстрого обеспечения покупателей необходимым медицинским оборудованием, а также с целью гарантии безопасности ' +
            'и правильной транспортировки на территории Смоленского района компания сформировала логистический технологический процесс, ' +
            'который соответствует требованиям законодательства РФ.',
        aboutUsText6: 'За многолетнюю работу с ЛПУ регионов компания зарекомендовала себя как надежный и ответственный поставщик' +
            ' услуг по поставке обслуживанию медицинской техники, что подтверждается многочисленными благодарственными письмами и ' +
            'ежегодным продлением сотрудничества с рядом основных медицинских учреждений. ',
        aboutUsSubTitle1: 'Учредительные документы',
        aboutUsSubTitle2: 'Внутренние документы общества',
        aboutUsSubTitle3: 'Юридические документы',
        aboutUsDocument1: 'Устав АО «Технологии и инновации»',
        aboutUsDocument2: 'Антикоррупционная политика АО «Технологии и инновации»',
        aboutUsDocument3: 'Решение о создании АО «Технологии и инновации»',
        aboutUsDocument4: 'ЕГРЮЛ о назначении руководителя Радговской Е.В.',
        aboutUsDocument6: 'Приказ о приеме на должность генерального директора Радговской Е.В.',
        aboutUsDocument5: 'Свидетельство о присвоении ОГРН',
        aboutUsDocument7: 'Аудиторское заключение',
        aboutUsDocument9: 'Свидетельство о постановке на учет в налоговом органе АО «Технологии и инновации» от 30.08.2017',
        aboutUsDocument10: 'Лист записи Единого государственного реестра юридицеских лиц',
        aboutUsDocument1HREF: '/file/Устав_ТИ_17.08.17.pdf',
        aboutUsDocument2HREF: '/file/Anticorruption.pdf',
        aboutUsDocument3HREF: '/file/Решение_о_создании_АО.pdf',
        aboutUsDocument4HREF: '/file/ЕГРЮЛ_назн.Радговской_.pdf',
        aboutUsDocument5HREF: '/file/ОГРН_Техн.инновации.pdf',
        aboutUsDocument6HREF: '/file/Приказ_о_приеме_Радговская_Е.В._на_должность_Гененрального_директора.pdf',
        aboutUsDocument7HREF: '/file/Аудиторское_заключение.pdf',
        aboutUsDocument9HREF: '/file/Свидетельство_о_постановке_на_учет.pdf',
        aboutUsDocument10HREF: '/file/ЕГРЮЛ.pdf',
        aboutUsAntiCorruption1: 'Деятельность нашей компании построена на поддержании авторитета и доверия к ней ' +
            'обязательными к исполнению всеми сотрудниками инструкциями, утвержденными в следующих внутренних документах:',
        aboutUsAntiCorruption2: '\n' +
            '                  Для осуществления эффективной политики руководству компании необходимо знать обо всех нарушениях.\n' +
            '                  Если вам стало известно о каких-либо фактах совершения коррупционных действий или склонения к их\n' +
            '                  совершению сотрудниками нашей компании, либо третьими лицами от лица «Технологии и инновации», либо о фактах\n' +
            '                  совершения коррупционных действий контрагентами или третьими лицами в отношении интересов\n' +
            '                  «Технологий и инноваций», пожалуйста, напишите нам на ',
        aboutUsAntiCorruption3: 'Мы гарантируем конфиденциальность вашего обращения.',
        aboutUsAntiCorruption4: 'По возможности, пожалуйста, укажите в своем обращении следующую информацию:',
        aboutUsAntiCorruption5: 'Ваши контактные данные (ФИО, телефон, email);',
        aboutUsAntiCorruption6: 'ФИО и должность сотрудника, которого склоняют к совершению коррупционных правонарушений\n' +
            '                              или который участвовал в совершении коррупционных правонарушений;',
        aboutUsAntiCorruption7: 'описание обстоятельств, при которых стало известно о совершении или склонении к\n' +
            '                              совершению коррупционного правонарушения (дата, место, время и тд);',
        aboutUsAntiCorruption8: 'подробное описание факта коррупционного правонарушения;',
        aboutUsAntiCorruption9: 'сведения о лице, склоняющем к коррупционному правонарушению;',
        aboutUsAntiCorruption10: 'информация об отказе (согласии) принять предложение.',
        partnershipHead: 'Преимущества',
        partnershipSubHead1: 'Географическое преимущество',
        partnershipSubHead2: '4 шага взаимодействия с клиентами:',
        partnershipSubHead3: 'Услуги и сервис',
        partnershipSubHead4: 'Виды услуг',
        partnershipSubHead5: 'Заказать услугу',
        partnershipSubHead6: 'Отчетность',
        partnershipSchemeSRC: '/img/scheme/ШАГИ.png',
        partnershipSubmenu1: 'Логистика',
        partnershipSubmenu2: 'Подать заявку',
        partnershipSubmenu3: 'Наши партнеры',
        partnershipSubmenu4: 'Наши клиенты',
        partnershipText1: 'Смоленская область - важнейший транспортный и коммуникационный узел. Через него проходят ' +
            'кратчайшие автомобильные и железнодорожные магистрали, связывающие Западную Европу с Центральной Россией. ' +
            'Такое расположение дает уникальные возможности с точки зрения оптимизации логистических процессов нашей ' +
            'компании',
        partnershipText2: 'Благодаря продуманной логистике, компания АО "Технологии и инновации" выбирает наиболее эффективные ' +
            'варианты обеспечения товаром нужного качества и количества, в нужное время, в нужном месте и с минимальными ' +
            'затратами.',
        partnershipText3: 'В зависимости от поставленной цели, компания применяет различные логистические системы:',
        partnershipText4: '- закупочная логистика;',
        partnershipText5: '- распределительная логистика;',
        partnershipText6: '- сбытовая логистика;',
        partnershipText7: '- транспортная логистика;',
        partnershipText8: '- складская логистика;',
        partnershipText9: '- информационная логистика.',
        partnershipText10: 'крупнейший в Смоленской области онлайн сервис поиска и заказа',
        partnershipText11: 'Наша компания предоставляет услуги по  комплексному техническому обслуживанию медицинского оборудования в соответствии с нормативно-правовыми актами РФ, услуги в сфере ремонта, диагностики, модернизации медицинской техники, подготовка и реализация комплексных решений по поставке, монтажу, вводу в эксплуатацию медицинского оборудования и обеспечение технического сервиса в гарантийный и пост гарантийный период.',
        partnershipText12: 'Ремонт медицинского оборудования осуществляется высококвалифицированными инженерами, имеющими подготовку и разрешительные документы на допуск к различным видам работ,' +
            ' в том числе специализированным, как в условиях лечебных учреждений, на месте размещения оборудования, так и на базе организованной ремонтной мастерской. Мы располагаем всем необходимым' +
            ' диагностическим и ремонтным оборудованием, которое помогает оперативно диагностировать и устранить проблему любой сложности.',
        partnershipText13: 'заказ',
        partnershipText14: '- формирование пользователем Интернет-аптеки заявки на товар с последующим его выкупом в одном из\n' +
            '                  аптечных пунктов сети "Городская аптека". Товар и аптечный пункт выбирается пользователем самостоятельно\n' +
            '                  из представленных на сайте',
        partnershipText15: 'Техническое обслуживание медицинского оборудования согласно регламенту производителя на соответствующее ' +
            'оборудование с заменой расходных материалов ',
        partnershipText16: 'Монтаж и ввод в эксплуатацию медицинского оборудования',
        partnershipText17: 'Модернизация, обновление и ремонт медицинской техники',
        partnershipText18: 'Обслуживание техники по гарантии и послегарантийный сервис ',
        partnershipText24: 'Инструментальный контроль',
        partnershipText25: 'Проверка сосудов под давлением ',
        partnershipText26: 'Подбор современного медицинского оборудования под конкретные задачи медицинского учреждения',
        partnershipText27: 'Диагностика, ремонт, настройка и восстановление медицинской техники',

        partnershipText19: 'По вопросам продвижения ассортимента на ',
        partnershipText20: 'По вопросам о сотрудничестве напишите нам',
        partnershipText21: 'или позвоните по телефону ',
        partnershipText22: 'Мы официальный сервис-партнер компании «A&D RUS» и имеем сервисный центр по адресу Николаева, д. 11 ' +
            '(в помещении нашей аптеки) по ремонту тонометров, ингаляторов и бытовых медицинских приборов производства A&D и др.',
        partnershipText23: 'Для формирования заявки на получение информации по продажам и товарному запасу, пожалуйста, заполните форму. ' +
            'На все ваши вопросы мы с удовольствием ответим по телефону',
        partnershipText28: 'Для подачи заявки на выполнение услуг Вы можете воспользоваться формой «Заказать услугу»  на данной ' +
            'странице. Наши сотрудники свяжутся с Вами в ближайшее время.',
        partnershipLogisticsNumericHead: 'Логистическая система в цифрах',
        partnershipWarehouseNumericHead: 'Технологические возможности',
        partnershipLogisticsSubHead1: 'Технологические возможности компании',
        partnershipNumeric1: 'Площадь охвата территории доставки',
        partnershipNumeric2: 'Cтран с возможностью доставки комплектующих\n' +
            'и медицинского оборудования\n',
        partnershipNumeric3: 'Автомобилей для доставки в день',
        partnershipNumeric4: 'Объем оборота медицинского оборудования',
        partnershipNumeric5: 'Затраты на складскую логистику',
        partnershipNumeric7: 'Более 10000 успешно выполненных ремонтов',
        partnershipNumeric8: 'Прямые доставки оборудования в Индию, Китай, Россию и страны СНГ',
        partnershipNumeric9: 'Отгрузка со склада',
        partnershipNumericNum1: 'Индия, Китай, Россия и страны СНГ',
        partnershipForSuppliersForm1: 'Наименование организации',
        partnershipForSuppliersForm2: 'Фактический адрес организации',
        partnershipForSuppliersForm3: 'E-mail',
        partnershipForSuppliersForm4: 'Телефон',
        partnershipForSuppliersForm5: 'Выбрать услугу',
        partnershipForSuppliersForm6: 'Код страны',
        partnershipForSuppliersForm6Error: 'Выберите код страны',
        partnershipForSuppliersForm7: 'Телефон',
        partnershipForSuppliersForm7Example: 'пример:',
        partnershipForSuppliersForm8: 'Прикрепить файлы',
        partnershipForSuppliersForm9: 'Комментарий',
        partnershipForSuppliersForm10: 'Отправить',
        partnershipForSuppliersForm11: 'Содержание заявки',
        partnershipForSuppliersForm12: 'Сформировать заявку',
        partnershipForSuppliersForm13: 'Заявка сформирована!',
        partnershipForSuppliersForm14: 'Вы не заполнили форму правильно',

        partnershipOurPartnersSubHead1: 'Крупнейшие поставщики ГЛС',
        partnershipOurPartnersText: 'Более 100 ведущих фармпроизводителей',
        partnershipOurPartnersSubHead2: 'Крупнейшие поставщики НЛС',
        partnershipOurPartnersSubHead3: 'Крупнейшие поставщики <<...>>',
        careerSubHead: 'Карьера в компании',
        careerSubTitle1: 'Мы предлагаем:',
        careerSubTitle2: 'Вопросы по трудоустройству?',
        careerSubTitle3: 'Вакансии',
        careerText1: 'В АО «Технологии и инновации» каждый сотрудник – полноценный партнер. Мы создаем комфортные условия работы, ' +
            'дружественную атмосферу, возможности для роста и построения карьеры, достойно вознаграждаем сотрудников и ' +
            'признаем тех, кто добивается высоких результатов.',
        careerText2: 'Карьерный и профессиональный рост',
        careerText3: 'Достойную заработную плату',
        careerText4: ' Всестороннее обучение и развитие',
        careerText5: 'Контактный телефон:',
        careerText6: 'Актуальную информацию о вакансиях компании Вы можете посмотреть на сайте',
        careerText7: 'Вы также можете прислать ваше резюме, используя следующую форму:',
        careerWorkForm1: 'ФИО',
        careerWorkForm2: 'Вакансия',
        careerWorkForm3: 'Город',
        careerWorkForm4: 'Прикрепить файлы',
        careerWorkForm5: 'Текст сообщения',
        careerWorkForm6: 'Отправить резюме',
        careerWorkForm7: 'Нажимая кнопку «Отправить», вы соглашаетесь с',
        careerWorkForm8: ' политикой обработки персональных данных',
        careerWorkForm9: 'Ваше резюме отправлено!',
        careerWorkForm10: 'Вы неправильно заполнили резюме',
        feedBackForm: 'Обратная связь',
        feedBackForm1: 'Как к Вам обращаться?',
        feedBackForm2: 'Укажите Email для обратной связи',
        feedBackForm3: 'Текст сообщения',
        feedBackForm4: 'Вы не заполнили форму правильно',
        contactSubTitle1: 'Центральный офис',
        contactSubTitle2: 'Ремонтно-производственный участок',
        contactText1: 'Россия, Смоленская область, Смоленский район, с. Михновка, ул. Солнечная, 1, оф.1',
        contactText2: '214012, Россия, г. Смоленск ул. Свердлова,4 оф.1',
        contactPhone: 'Телефон',
        contactText3: 'Будем рады Вашим отзывам и предложениям!',
        contactText4: 'Напишите нам',
        contactText5: 'Либо заполнив',
        contactText6: 'форму обратной связи',
        contactInfo: 'Все права защищены ©, 2019-2020 АО «Технологии и инновации». Верстка и программирование: ',
        contactITMosaic: ' “ИТ-Мозаика”'
      }
    },
    'en-US': {
      message: {
        companyName: 'Technology and innovation',
        slogan: 'with care for your health!',
        linkView: 'View',
        submenu1: 'About company',
        submenu2: 'Partnership',
        submenu3: 'Career',
        submenu4: 'Contact info',
        sliderHeader1: 'Company',
        sliderHeader2: 'Activity',
        sliderHeader3: 'Opportunities',
        sliderText1: 'JSC "Technologies and Innovations" - a company that occupies a leading position in the supply and service of ' +
            'medical equipment in the Smolensk, Bryansk and Kaluga regions.',
        sliderText2: 'When performing maintenance of medical equipment, the main emphasis is on the quality and efficiency of work,' +
            ' as well as the maximum extension of the service life of the medical device while maintaining its operational characteristics.',
        sliderText3: 'We provide a full range of services related ti the maintenance of medical equipment',
        sliderButton1: 'More details',
        sliderButton2: 'More details',
        sliderButton3: 'More details',
        allNews: 'All news',
        contentUnit1: 'thou.',
        contentUnit4: 'cbm',
        contentNumericHead: 'Digits',
        contentNumeric1: 'More than 25 000 products in assortment',
        contentNumeric2: 'Total area of warehouse premises',
        contentUnit2: 'sqm',
        contentNumeric3: 'Annual turnover: more than 200 million rubles',
        contentUnit3: 'mln rub',
        contentNumeric4: 'More than 10 highly qualified employees',
        contentNum5: 'Sufficient',
        contentNumeric5: 'Market share',
        contentUnit5: '%',
        contentNum6: 'Russia and the CIS countries',
        contentNum7: 'Extensive',
        contentNum8: 'Sufficient',
        contentNumeric6: 'Coverage of JSC Technology and innovation',
        contentUnit6: 'mln cbm',
        contentNumeric17: 'Total area of warehouse premises',
        contentNumeric18: 'More than 10000 successfully completed repairs',
        contentNumeric19: 'Repair of more than 1000 types of medical equipment',
        contentUnit7: 'sqkm',
        contentNumeric7: 'Cooperation with more than 50 manufacturers of medical equipment',
        contentNumeric8: 'More then 50 pharmacies',
        contentNumeric9: 'Manufacturers',
        aboutUsSubmenu1: 'Management',
        aaboutUsManagementFIO1: 'Radgovskaya Elizaveta Vladimirovna',
        aboutUsManagementPost1: 'General manager',
        aboutUsManagementFIO2: 'Karpov Alexey Anatolyevich',
        aboutUsManagementPost2: 'Chief Engineer for repair and maintenance of medical equipment',
        aboutUsManagementFIO3: 'Mishchenkov Vitaly Viktorovich',
        aboutUsManagementPost3: 'Medical Equipment Repair and Maintenance Engineer',
        aboutUsManagementFIO4: 'Sergey Vladimirovich Sitnikov',
        aboutUsManagementPost4: 'Medical Equipment Repair and Maintenance Engineer',
        aboutUsManagementFIO5: 'Petrov Valery Petrovich ',
        aboutUsManagementPost5: 'Service Center and A&D Engineer',
        aboutUsManagementFIO6: 'Prokhorenkova Evgeniya Aleksandrovna',
        aboutUsManagementPost6: 'Chief accountant',
        aboutUsManagementFIO7: ' Kondrashov Andrey Aleksandrovich',
        aboutUsManagementPost7: 'Dental Equipment Repair Engineer',
        aboutUsSubmenu2: 'Documents',
        aboutUsSubmenu3: 'Types of activities',
        aboutUsSubmenu31: 'Licenses and types of activities',
        aboutUsTypesOfActivitiesSveden: 'Data on types of activities of the organization according to EGRUL',
        aboutUsTypesOfActivitiesEGRUL: 'Unified State Register of Legal Entities of the Federal Tax Service of Russia',
        aboutUsTypesOfActivitiesOsnTitle: 'Main',
        aboutUsTypesOfActivitiesOsn: 'Repair and maintenance of medical equipment',
        aboutUsTypesOfActivitiesDop: 'Additional',
        aboutUsTypesOfActivitiesDop1: 'Delivery, installation and commissioning of medical equipment',
        aboutUsTypesOfActivitiesDop2: 'Delivery and installation of spare parts for medical equipment',
        aboutUsTypesOfActivitiesDop3: 'Technical advisory support',
        aboutUsTypesOfActivitiesDop4: 'Modernization of old medical devices',
        aboutUsSubmenu4: 'Anti-corruption policy',
        aboutUsSubmenu5: 'News',
        aboutUsText1: 'Repair and maintenance of medical equipment is carried out by highly qualified engineers who have ' +
            'training and valid permits for performing various types of work, including specialized ones. All technical workers, ' +
            'without exception, undergo retraining and advanced training courses in various areas of medical equipment ' +
            'maintenance in specialized specialized training centers.',
        aboutUsText2: 'Equipment repairs, including capital repairs, are carried out both in the conditions of medical ' +
            'institutions, at the location of the equipment, and on the basis of an organized repair shop with a pilot ' +
            'production site. The presence of an experimental site allows you to perform repair work of any complexity, and ' +
            'independently perform turning and milling operations for the manufacture of discontinued or hard-to-reach mechanical parts.',
        aboutUsText3: 'To provide customers with the necessary medical equipment as quickly as possible. in order to guarantee the safety and proper ' +
            'transportation on the territory of the Smolensk region, the company has formed a logistics technological process that meets ' +
            'the requirements of the legislation of the Russian Federation.',
        aboutUsText6: 'For many years of work with the health care centers of the regions, the company has established ' +
            'itself as a reliable and responsible provider of services for the supply and maintenance of medical equipment, ' +
            'which is confirmed by numerous letters of thanks and the annual extension of cooperation with a number of major medical institutions.',
        aboutUsSubTitle1: 'Constituent documents',
        aboutUsSubTitle2: 'Internal documents of the company',
        aboutUsSubTitle3: 'Legal Documents',
        aboutUsDocument1: 'Charter of JSC Technologies and Innovations',
        aboutUsDocument2: 'Anti-corruption policy of JSC Technologies and Innovations',
        aboutUsDocument3: 'The decision to create JSC Technologies and Innovations',
        aboutUsDocument4: 'Unified State Register of Legal Entities on the appointment of the head of Radgovskaya E. V.',
        aboutUsDocument5: 'PSRN assignment certificate',
        aboutUsDocument6: 'Order on the appointment of General Director E. V. Radgovskaya',
        aboutUsDocument7: 'Audit conclusion',
        aboutUsDocument8: 'Notification of deregistration of a Russian organization in a tax authority of Technology and innovation JSC dd 13.09.2019',
        aboutUsDocument9: 'Certificate of registration with the tax authority of JSC Technologies and Innovations dd 30.08.2017',
        aboutUsDocument10: 'Record sheet of the Unified State Register of Legal Entities',
        aboutUsDocument1HREF: '/file/Устав_ТИ_17.08.17.pdf',
        aboutUsDocument2HREF: '/file/Anticorruption.pdf',
        aboutUsDocument3HREF: '/file/Решение_о_создании_АО.pdf',
        aboutUsDocument4HREF: '/file/ЕГРЮЛ_назн.Радговской_.pdf',
        aboutUsDocument5HREF: '/file/ОГРН_Техн.инновации.pdf',
        aboutUsDocument6HREF: '/file/Приказ_о_приеме_Радговская_Е.В._на_должность_Гененрального_директора.pdf',
        aboutUsDocument7HREF: '/file/Аудиторское_заключение.pdf',
        aboutUsDocument9HREF: '/file/Свидетельство_о_постановке_на_учет.pdf',
        aboutUsDocument10HREF: '/file/ЕГРЮЛ.pdf',
        aboutUsAntiCorruption1: 'The activity of our company is built on maintaining credibility and trust in it with ' +
            'binding instructions for all employees approved in the following internal documents:',
        aboutUsAntiCorruption2: 'To implement an effective policy, company management needs to be aware of all ' +
            'violations. If you become aware of any facts of corruption or incitement to commit them by employees of our ' +
            'company, or by third parties on behalf of Technology and innovation, or of facts of corruption by contractors or third ' +
            'parties regarding the interests of Technology and innovation please write to us at ',
        aboutUsAntiCorruption3: 'We guarantee the confidentiality of your appeal.',
        aboutUsAntiCorruption4: 'If possible, please indicate in your appeal the following information:',
        aboutUsAntiCorruption5: 'Your contact information (name, phone, email);',
        aboutUsAntiCorruption6: 'name and position of the employee who is persuaded to commit corruption offenses or who ' +
            'participated in the commission of corruption offenses;',
        aboutUsAntiCorruption7: 'a description of the circumstances in which it became known about the commission or ' +
            'incitement to commit a corruption offense (date, place, time, etc.);',
        aboutUsAntiCorruption8: 'a detailed description of the fact of a corruption offense;',
        aboutUsAntiCorruption9: 'information about the person who incites to a corruption offense;',
        aboutUsAntiCorruption10: 'information about the refusal (consent) to accept the offer.',
        partnershipHead: 'Advantages',
        partnershipSubHead1: 'Geographical advantage',
        partnershipSubHead2: '4 steps of interaction with clients:',
        partnershipSubHead3: 'Services',
        partnershipSubHead4: 'Types of services',
        partnershipSubHead5: 'Order service',
        partnershipSubHead6: 'Reporting',
        partnershipSchemeSRC: '/img/scheme/ШАГИ.png',
        partnershipSubmenu1: 'Logistics',
        partnershipSubmenu2: 'Apply',
        partnershipSubmenu3: 'Our partners',
        partnershipSubmenu4: 'Our clients',
        partnershipText1: 'Smolensk region is the most important transport and communication hub. The shortest ' +
            'highways and railways connecting Western Europe with Central Russia pass through it. This arrangement ' +
            'provides unique opportunities in terms of optimizing the logistics processes of our company.',
        partnershipText2: 'Thanks to thoughtful logistics, the company Technology and innovation JSC chooses the most effective ' +
            'options for providing goods with the right quality and quantity, at the right time, in the right place and ' +
            'at the lowest cost.',
        partnershipText3: 'Depending on the goal, the company uses various logistics systems:',
        partnershipText4: '- purchasing logistics;',
        partnershipText5: '- distribution logistics;',
        partnershipText6: '- sales logistics;',
        partnershipText7: '- transport logistics;',
        partnershipText8: '- warehouse logistics;',
        partnershipText9: '- information logistics.',
        partnershipText10: 'the largest online service in the Smolensk region for searching and ordering',
        partnershipText11: 'Our company provides services for complex maintenance of medical equipment in accordance with the regulatory legal ' +
            'acts of the Russian Federation, services in the field of repair, diagnostics, modernization of medical equipment, preparation and ' +
            'implementation of complex solutions for the supply, installation, commissioning of medical equipment and providing technical service ' +
            'during the warranty and post-warranty period.',
        partnershipText12: 'The repair of medical equipment is carried out by highly qualified engineers who have training and permits for ' +
            'admission to various types of work, including specialized ones, both in the conditions of medical institutions, at the location ' +
            'of the equipment, and on the basis of an organized repair shop. We have all the necessary diagnostic and repair equipment that ' +
            'helps to quickly diagnose and fix a problem of any complexity.',
        partnershipText13: 'order',
        partnershipText14: '- the formation by the user of an online pharmacy of an application for a product with its ' +
            'subsequent redemption at one of the pharmacy points of the City Pharmacy network. The product and pharmacy ' +
            'is selected by the user independently from those presented on the site',
        partnershipText15: 'Maintenance of medical equipment in accordance with the manufacturer\'s regulations for the corresponding equipment with the replacement of consumables',
        partnershipText16: 'Installation and commissioning of medical equipment',
        partnershipText17: 'Modernization, renewal and repair of medical equipment',
        partnershipText18: 'Warranty service and post-warranty service',
        partnershipText24: 'Instrumental control',
        partnershipText25: 'Checking pressure vessels ',
        partnershipText26: 'Selection of modern medical equipment for specific tasks of a medical institution',
        partnershipText27: 'Diagnostics, repair, configuration and restoration of medical equipment',
        partnershipText19: 'Regarding the promotion of the assortment at ',
        partnershipText20: 'If you have any questions about cooperation, please contact us',
        partnershipText21: 'or call ',
        partnershipText22: 'We are always open for mutually beneficial partnership. To start cooperation with our company, ' +
            'you can make an application for inclusion of goods in our price list by filling out the following form',
        partnershipText23: 'To generate an application for information on sales and inventory, please fill out the form.' +
            'We will be happy to answer all your questions by phone',
        partnershipText28:'To apply for services, you can use the "Order a Service" form on this page. Our staff ' +
            'will contact you as soon as possible.',
        partnershipSubTitle: 'Logistics',
        partnershipLogisticsNumericHead: 'Logistics system in numbers',
        partnershipWarehouseNumericHead: 'Warehouse system in numbers',
        partnershipNumeric1: 'Delivery area coverage area',
        partnershipNumeric2: 'Countries with the ability to deliver components and medical equipment',
        partnershipNumeric3: 'Number of vehicles used for the delivery per day',
        partnershipNumeric4: 'Volume of medical equipment turnover',
        partnershipNumeric5: 'Expenses for warehouse logistics',
        partnershipNumeric7: 'More than 10,000 successfully completed repairs',
        partnershipNumeric8: 'Direct delivery of equipment to India, China, Russia and CIS countries',
        partnershipNumeric9: 'Shipment from the warehouse',
        partnershipNumericNum1: 'India, China, Russia and the CIS countries',
        partnershipLogisticsSubHead1: 'Technological capabilities of the company',
        partnershipForSuppliersForm1: 'Product Name',
        partnershipForSuppliersForm2: 'Company',
        partnershipForSuppliersForm3: 'Your company website',
        partnershipForSuppliersForm4: 'Region',
        partnershipForSuppliersForm5: 'Contact person',
        partnershipForSuppliersForm6: 'Country code',
        partnershipForSuppliersForm6Error: 'Choose country code',
        partnershipForSuppliersForm7: 'Phone number',
        partnershipForSuppliersForm7Example: 'example:',
        partnershipForSuppliersForm8: 'Attach files',
        partnershipForSuppliersForm9: 'Comment',
        partnershipForSuppliersForm10: 'Send',
        partnershipForSuppliersForm11: 'Application Content',
        partnershipForSuppliersForm12: 'To form a request',
        partnershipForSuppliersForm13: 'Application is formed!',
        partnershipForSuppliersForm14: 'You didn\'t fill out the form correctly',
        partnershipOurPartnersSubHead1: 'Key Medicine Suppliers',
        partnershipOurPartnersText: 'Over 100 leading pharmaceutical manufacturers',
        partnershipOurPartnersSubHead2: 'Key Non-Medcines Suppliers',
        partnershipOurPartnersSubHead3: 'Key <<<...>> Suppliers',
        careerSubHead: 'Company career',
        careerSubTitle1: 'We offer:',
        careerSubTitle2: 'Questions about employment?',
        careerSubTitle3: 'Jobs',
        careerText1: 'At Technologies and Innovations JSC, each employee is a full-fledged partner. We create comfortable working ' +
            'conditions, a friendly atmosphere, opportunities for growth and career building, adequately reward ' +
            'employees and recognize those who achieve high results.',
        careerText2: 'Career and professional growth',
        careerText3: 'Decent salary',
        careerText4: 'Comprehensive training and development',
        careerText5: 'Contact number:',
        careerText6: 'You can look up-to-date information on company vacancies on the website',
        careerText7: 'You can also send your resume using the following form:',
        careerWorkForm1: 'Full name',
        careerWorkForm2: 'Vacancy',
        careerWorkForm3: 'City',
        careerWorkForm4: 'Attach files',
        careerWorkForm5: 'Message text',
        careerWorkForm6: 'Send CV',
        careerWorkForm7: 'By clicking the "Send" button, you agree to',
        careerWorkForm8: ' the policy of processing personal data',
        careerWorkForm9: 'Your resume has been sent!',
        careerWorkForm10: 'You filled out your resume incorrectly',
        feedBackForm: 'Feedback',
        feedBackForm1: 'How can I call you?',
        feedBackForm2: 'Indicate Email for feedback',
        feedBackForm3: 'Message text',
        feedBackForm4: 'You didn\'t fillout theform correctly',
        contactSubTitle1: 'Main office',
        contactSubTitle2: 'Repair and production site',
        contactText1: 'Russia, Smolensk region, Smolensk district, v.Mikhnovka,  1 Solnechnaya street, off.1',
        contactText2: ' 214012,Russia, city Smolensk , 4 Sverdlova street, off.1',
        contactPhone: 'Phone',
        contactText3: 'We welcome your feedback and suggestions!',
        contactText4: 'Email us',
        contactText5: 'Or by filling out',
        contactText6: 'the feedback form',
        contactInfo: 'All rights reserved ©, 2019-2020 JSC Technology and innovation. Site creation: ',
        contactITMosaic: ' “IT-Mosaic”'
      }
    }
  }
})

/**
 * @param {String} locale
 */
export async function loadMessages (locale) {
  if (Object.keys(i18n.getLocaleMessage(locale)).length === 0) {
    const messages = await import(/* webpackChunkName: "[request]" */ `@/lang/${locale}`)
    i18n.setLocaleMessage(locale, messages)
  }

  if (i18n.locale !== locale) {
    i18n.locale = locale
  }
}

(async function () {
  await loadMessages(store.getters['lang/locale'])
})()

export default i18n
