const Error404 = () => import(/* webpackChunkName: "errors" */ '@/views/errors/404')
const Main = () => import(/* webpackChunkName: "about" */ '@/views/Main')
const AboutCompany = () => import(/* webpackChunkName: "about" */ '@/views/AboutCompany')
const Partnership = () => import(/* webpackChunkName: "about" */ '@/views/Partnership')
const Career = () => import(/* webpackChunkName: "about" */ '@/views/Career')
const Contacts = () => import(/* webpackChunkName: "about" */ '@/views/Contacts')
const Management = () => import(/* webpackChunkName: "about" */ '@/views/Management')
const Documents = () => import(/* webpackChunkName: "about" */ '@/views/Documents')
const TypeOfActivities = () => import(/* webpackChunkName: "about" */ '@/views/TypeOfActivities')
const AnticorruptionPolicy = () => import(/* webpackChunkName: "about" */ '@/views/AnticorruptionPolicy')
const AllNews = () => import(/* webpackChunkName: "about" */ '@/views/AllNews')
const NewsPage = () => import(/* webpackChunkName: "about" */ '@/views/NewsPage')
const Logistics = () => import(/* webpackChunkName: "about" */ '@/views/Logistics')
const ForSuppliers = () => import(/* webpackChunkName: "about" */ '@/views/ForSuppliers')
const OurPartners = () => import(/* webpackChunkName: "about" */ '@/views/OurPartners')
const OurClients = () => import(/* webpackChunkName: "about" */ '@/views/OurClients')

export default [
  { path: '/', name: 'welcome', component: Main },
  { path: '*', name: '404', component: Error404 },
  { path: '/about_company', name: 'about_company', component: AboutCompany },
  { path: '/partnership', name: 'partnership', component: Partnership },
  { path: '/career', name: 'career', component: Career },
  { path: '/contacts', name: 'contacts', component: Contacts },
  { path: '/management', name: 'management', component: Management },
  { path: '/documents', name: 'documents', component: Documents },
  { path: '/types_of_activities', name: 'types_of_activities', component: TypeOfActivities },
  { path: '/anticorruption_policy', name: 'anticorruption_policy', component: AnticorruptionPolicy },
  { path: '/news', name: 'news', component: AllNews },
  { path: '/news_page', name: 'news_page', component: NewsPage },
  { path: '/logistics', name: 'logistics', component: Logistics },
  { path: '/for_suppliers', name: 'for_suppliers', component: ForSuppliers },
  { path: '/our_partners', name: 'our_partners', component: OurPartners },
  { path: '/our_clients', name: 'our_clients', component: OurClients }
]
